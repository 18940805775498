import { ArrowPathIcon, ChatBubbleOvalLeftEllipsisIcon, CloudArrowUpIcon, FingerPrintIcon, LockClosedIcon, UserGroupIcon, WrenchIcon } from '@heroicons/react/24/outline'
const aboutUSdata = [
    {
        name: 'Diversity',
        description:
            "Race, gender, and personality are all a vital part of our team's plan to provide opportunities for kids in our school to participate in an engineering club. Our teams diversity: 54% Indian, 40% Women, 33% White, 13% East, Asian 3% LGBTQIA",
        icon: UserGroupIcon,
    },
    {
        name: 'Innovation',
        description:
            "Our team is focused on innovation for success. Focusing on risk-based design we combine the excitement of competitive sports with the rigors of science and technology. We maxing material efficient driven by limited supplies and funding. this leads to minimizing waste and maximizing the material value. We leverage critical thinking to go through design selection. We prioritize designs that enable game winning strategy plays ",
        icon: WrenchIcon,
    },
    {
        name: 'Collaboration',
        description:
            'We have fun while designing and building the robot. we focus on networking with industries professional, empowering each team member to lead and own a segment in the robot development journey. This includes both technical development and team spirit. from fundraising, swag design and T-shirt printing to metal welding, electrical and software coding. Everyone had a job, and owns it.',
        icon: ChatBubbleOvalLeftEllipsisIcon,
    },

]
export default aboutUSdata;