import React from 'react';
const awards = () => {
    return (
<div>
    <img
        src="/assets/images/2264awards.png"
        width='600'
        
        />
</div>
    )
}
export default awards;