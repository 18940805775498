const events = [

    {
        name: "MNHSL State Competition",
        month: "May",
        date: "4",
        location: "University of Minnesota"
    },

]

export default events;
