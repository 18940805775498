const OutReachData = [
    {
        blogName: "Wayzata Highschool Football Games",
        author: "Yoojin Nam",
        date: "9/12/24, 9/20/24, 10/10/24",
        blog: "Our team further connects with our school's student body by participating in football games and shooting T-shirts to the student section! The interactions with the crowd not only strengthen our bond with the community, but gives us energy we need for our team spirit.",
        imgSrc: "/mentorImages/headCoach.jpg",
        year:"2024"
    },
    {
        blogName: "Wayzata Homecoming Block Party",
        author: "Dhriti Edpuganti",
        date: "9/20/24",
        blog: "At our high school's block party, we showcased our robot and we showed school pride by coming and cheering on our school!",
        imgSrc: "/mentorImages/headCoach.jpg",
        year:"2024"
    },
    {
        blogName: "Minnesota State Fair",
        author: "Dhriti Edpuganti",
        date: "8/22/24",
        blog: "Rain didn't stop us from bringing awareness of FIRST's competitions, espcially FIRST Robotics Competition, also known as FRC",
        imgSrc: "/mentorImages/headCoach.jpg",
        year: "2024"
    },
    {
        blogName: "Asian Fair",
        author: "Dhriti Edpuganti",
        date: "6/1/24",
        blog: "We had a booth to showcase the Wayzata Robotics Team and to explain what FRC is at the Plymouth Asian Fair.",
        imgSrc: "/mentorImages/headCoach.jpg",
        year: "2024"
    },
    {
        blogName: "Plymouth City Council",
        author: "Dhriti Edpuganti",
        date: "4/23/24",
        blog: "We were invited to present at the Plymouth City Hall where we talked to the board about our team, how we function, and what our goals are.",
        imgSrc: "/mentorImages/headCoach.jpg",
        year: "2024"
    },
    {
        blogName: "Discover Plymouth",
        author: "Dhriti Edpuganti",
        date: "3/23/24",
        blog: "Discover Plymouth is where we got to present to many business owners and tell them what we are doing.",
        imgSrc: "/mentorImages/headCoach.jpg",
        year: "2024"
    },
    {
        blogName: "Sunset Hill Fair",
        author: "Dhriti Edpuganti",
        date: "3/21/24",
        blog: "We taught them about the lower levels of FIRST Robotics and had a fun interactive game.",
        imgSrc: "/mentorImages/headCoach.jpg",
        year: "2024"
    },
    {
        blogName: "Oakwood Elementary Science Fair",
        author: "Lakshmi Kandikonda",
        date: "2/27/24",
        blog: "As a part of our outreach program dedicated to promoting STEAM, our robotics team hosted a booth at Oakwood Elementary's Science Fair! We had engaging mini-games such as frisbee toss that was related to this year's FRC challenge. This not only served as an entertainment for the elementray schoolers, but also got them interested in robotics. Additionally, we proudly presented our local FLL and FTC teams, showcasing their robots at the event as well. Overall, our participation in Oakwood Elementary's Science Fair marked a successful outreach effort!",
        imgSrc: "/mentorImages/headCoach.jpg",
        year:"2024"
    },
    {
        blogName: "Wayzata Homecoming Block Party",
        author: "Avi bandi",
        date: "12/9/23",
        blog: "Our robotics team recently traveled to Plymouth to take part in a local parade. We towed a float showcasing our team's technical prowess and engaged with the community along the lively parade route. At our booth, we went beyond just talking about robotics – we provided a hands-on experience for little kids, letting them interact with our robots to inspire an early interest in science and technology. This event not only highlighted our team's achievements in robotics but also deepened our ties with the local community, fostering a sense of connection and enthusiasm for STEM.",
        imgSrc: "/mentorImages/headCoach.jpg",
        year:"2023"
    },
    {
        blogName: "Trojan Robotics at Plymouth on Parade",
        author: "Avi bandi",
        date: "12/9/23",
        blog: "Our robotics team recently traveled to Plymouth to take part in a local parade. We towed a float showcasing our team's technical prowess and engaged with the community along the lively parade route. At our booth, we went beyond just talking about robotics – we provided a hands-on experience for little kids, letting them interact with our robots to inspire an early interest in science and technology. This event not only highlighted our team's achievements in robotics but also deepened our ties with the local community, fostering a sense of connection and enthusiasm for STEM.",
        imgSrc: "/mentorImages/headCoach.jpg",
        year:"2023"
    },
    {
        blogName: "Medtronic Jumpstart Event",
        author: "Dhriti Edpuganti",
        date: "10/28/23",
        blog: "We were able to watch multiple different presentations and learn how other teams are doing.",
        imgSrc: "/mentorImages/headCoach.jpg",
        year: "2023"
    },
    {
        blogName: "Asian Fair",
        author: "Dhriti Edpuganti",
        date: "6/1/23",
        blog: "We had a booth and talked to the Plymouth community and showed them who we are and what we do.",
        imgSrc: "/mentorImages/headCoach.jpg",
        year: "2023"
    }
]
export default OutReachData
