import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/footer";

export default function Newsletter() {
    return (
        <div>
            <NavBar />
        
                 <embed width='100%' height='1150' src="/assets/images/FebruaryNewsletter2024.pdf"></embed>           <Footer />
        </div>
    )
}